<template>
  <footer v-if="!shouldBePriceHidden()" class="card-footer">
    <ProductPrice :product="product" />
    <div class="card-footer__button">
      <ButtonBuy :product="product" :minified-view="true" />
    </div>
  </footer>
</template>

<script setup>
import { useHiddenPricePolicies } from "~/uses/useHiddenPricePolicies";
import ButtonBuy from "~/modules/shared/components/buttons/ButtonBuy.vue";
import { Product } from "~/models/product.model";
import ProductPrice from "~/modules/shared/components/price/ProductPrice.vue";

const props = defineProps({
  product: { type: Product, required: true },
});

const shouldBePriceHidden = () => {
  return useHiddenPricePolicies().some((hiddenPricePolicy) =>
    hiddenPricePolicy(),
  );
};
</script>

<style lang="scss" scoped>
.card-footer {
  @include flex-container(row, space-between, center);
  gap: 8px;

  &__button {
    margin-left: auto;
  }
}
</style>
